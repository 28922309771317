import { VisAgeReport } from './modules/age_report.js';
import { AmountDistributionBars } from './modules/amount_distribution_bars';
import { Areachart } from './modules/areachart';
import { VisBubbles } from './modules/bubbles.js';
import { VisBubble } from './modules/bubble_cost.js';
import { VisBubbleLegend } from './modules/bubble_legend.js';
import { BarsCard } from './modules/card_bars.js';
import { ComparisonCard } from './modules/card_comparison.js';
import { SimpleCard } from './modules/card_simple.js';
import { SparklineTableCard } from './modules/card_sparkline_table.js';
import { TableCard } from './modules/card_table.js';
import { VisIndicators } from './modules/consultation_indicators.js';
import { GroupPctDistributionBars } from './modules/group_pct_distribution_bars';
import { VisLineasJ } from './modules/lineas_tabla.js';
import { VisLinesExecution } from './modules/lines_execution.js';
import { VisPopulationPyramid } from './modules/population_pyramid.js';
import { Punchcard } from './modules/punchcard.js';
import { VisRentDistribution } from './modules/rent_distribution.js';
import { Rowchart } from './modules/rowchart.js';
import { VisSlider } from './modules/slider.js';
import { Sparkline } from './modules/sparkline.js';
import { VisTreemap } from './modules/treemap.js';
import { VisUnemploymentAge } from './modules/unemployment_age.js';
import { VisUnemploymentRate } from './modules/unemployment_rate.js';
import { VisUnemploymentSex } from './modules/unemployment_sex.js';

export {
  Areachart,
  VisAgeReport,
  VisBubbleLegend,
  VisBubbles,
  VisBubble,
  BarsCard,
  ComparisonCard,
  SimpleCard,
  SparklineTableCard,
  TableCard,
  VisIndicators,
  VisLineasJ,
  VisLinesExecution,
  VisPopulationPyramid,
  Punchcard,
  VisRentDistribution,
  Rowchart,
  VisSlider,
  Sparkline,
  VisTreemap,
  VisUnemploymentAge,
  VisUnemploymentRate,
  VisUnemploymentSex,
  AmountDistributionBars,
  GroupPctDistributionBars
};
